$lightGrey: #ececec;
$grey: #ddd;
$darkGrey: #969696;

$corporateColor: #aa113f;
$dangerColor: #dd4b39;
$yellowBackground: #f39c12;

@import "avia";
@import "calendar";


// --- markdown editor -----

.CodeMirror {
  min-height: 80px;
  &-scroll {
    min-height: 80px!important;
  }
}

.direct-chat-messages {
  .direct-chat-text {
    img {
      max-width: 600px;
      max-height: 500px;
      &.fullview {
        max-width: 100%;
        max-height: 1000px;
      }
    }

    @media (max-width: 1000px) {
      img {
        max-width: 100% !important;
      }
    }
  }

  .editor-statusbar {
    position: absolute;
    right: 5px;
  }

  table {
    width: 100%;
    background-color: white;

    tr {
      border-bottom: 1px solid #f2f2f2;
      &:first-child {
        border-top: 1px solid #f2f2f2;
      }
      td, th {
        padding: 5px 20px;
      }
    }
  }

}

// --- \\ markdown editor -----


table.num-change {
  border-radius: 5px;
  tr {
    td {
      border: 1px solid $grey;
      padding: 4px 12px;
      font-size: 120%;

      &:first-child, &:last-child {
        font-size: 100%;
        background-color: $lightGrey;
        font-weight: normal;
        padding: 4px 10px;
        cursor: pointer;
      }
    }
  }
}

table.num-change.num-inline {
  display: inline-block;
  vertical-align: middle;
  margin: 1px;
}

table.num-change.num-small {
  border-radius: 5px;
  tr {
    td {
      i.fa {
        font-size: 90%;
      }

      border: 1px solid $grey;
      padding: 2px 6px;
      font-size: 90%;

      &:first-child, &:last-child {
        font-size: 80%;
        background-color: $lightGrey;
        font-weight: normal;
        padding: 2px 6px;
        cursor: pointer;
      }
    }
  }
}




.uib-datepicker-popup,
.datetime-picker-dropdown {
  border-radius: 0px;
  border: 1px solid $grey;
  top: 33px!important;
  background-color: white;

  .uib-datepicker {

    button.active {
      background-color: $corporateColor;
      border: 0px;
      color: white;

      span {
        color: white;
      }
    }
  }

  button {
    background-color: transparent;
    border: 0px;

    &.btn-info, &.btn-danger, &.btn-success, &.datetimepicker-ctrl-btn {
      background-color: #f4f4f4;
      border: 1px dashed #ddd;
      color: black;
    }

  }
  .btn-group>.btn:last-child:not(:first-child) {
    border-left: 1px solid white;

  }
}

.btn-danger:focus {
  background-color: $corporateColor!important;
}

.popover.ng-isolate-scope {
  visibility: visible;
  display: block;
}

.alert, .callout {
  position: relative;
}

.alert.alert-super-danger {
  background-color: $dangerColor!important;
  color: white;
}

.label.label-super-danger {
  background-color: $dangerColor!important;
  color: white;
}

.callout.callout-super-danger {
  background-color: $dangerColor!important;
  color: white;
  border-left: 5px #c23321 solid;
}

.popover {
  max-width: 450px!important;
}

.bg-gray-with-border {
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  margin: 10px;
  padding: 15px;
}

.uib-datepicker:focus,
.uib-daypicker:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: none;
  outline: 0 none;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

[ng-click] {
  cursor: pointer;
}

input, select {
  &.ng-invalid.ng-touched {
    background-color: #fad4d0;
    border-color: $corporateColor;
  }
}

.ng-invalid.ng-submitted {
  input, select {
    &.ng-invalid {
      background-color: #fad4d0;
      border-color: $corporateColor;

    }
  }
}

input, select {
  &.ng-valid.ng-touched {
    // background-color: #abfab3;
  }
}

.modal-content {
  background-color: inherit;

}

.btn-fixed {
  text-align: left;
  padding: 6px 16px;
  i {
    margin-right: 10px;
  }
}

.markdown-area {
  img {
    max-width: 100%;
  }
}

.margin-top-5 {
  margin-top: 5px!important;
}

.margin-top-10 {
  margin-top: 10px!important;
}

.margin-top-15 {
  margin-top: 15px!important;
}

.margin-top-20 {
  margin-top: 20px!important;
}

.margin-top-25 {
  margin-top: 25px!important;
}

.margin-bottom-5 {
  margin-bottom: 5px!important;
}

.margin-bottom-10 {
  margin-bottom: 10px!important;
}

.margin-bottom-15 {
  margin-bottom: 15px!important;
}

.margin-bottom-20 {
  margin-bottom: 20px!important;
}

.margin-bottom-25 {
  margin-bottom: 25px!important;
}

.margin-left-5 {
  margin-left: 5px!important;
}

.margin-left-10 {
  margin-left: 10px!important;
}

.margin-left-15 {
  margin-left: 15px!important;
}

.margin-right-5 {
  margin-right: 5px!important;
}

.margin-right-10 {
  margin-right: 10px!important;
}

.margin-right-15 {
  margin-right: 15px!important;
}

.width-150 {
  width: 150px!important;
}

.width-175 {
  width: 175px!important;
}

.width-200 {
  width: 200px!important;
}

.width-225 {
  width: 225px!important;
}

.width-250 {
  width: 250px!important;
}

.font-size-200 {
  font-size:200%!important;
}

.font-size-195 {
  font-size:195%!important;
}

.font-size-190 {
  font-size:190%!important;
}

.font-size-185 {
  font-size:185%!important;
}

.font-size-180 {
  font-size:180%!important;
}

.font-size-175 {
  font-size:175%!important;
}

.font-size-170 {
  font-size:170%!important;
}

.font-size-165 {
  font-size:165%!important;
}

.font-size-160 {
  font-size:160%!important;
}

.font-size-155 {
  font-size:155%!important;
}

.font-size-150 {
  font-size:150%!important;
}

.font-size-145 {
  font-size:145%!important;
}

.font-size-140 {
  font-size:140%!important;
}

.font-size-135 {
  font-size:135%!important;
}

.font-size-130 {
  font-size:130%!important;
}

.font-size-125 {
  font-size:125%!important;
}

.font-size-120 {
  font-size:120%!important;
}

.font-size-115 {
  font-size:115%!important;
}

.font-size-110 {
  font-size:110%!important;
}

.font-size-105 {
  font-size:105%!important;
}

.font-size-95 {
  font-size:95%!important;
}

.font-size-90 {
  font-size:90%!important;
}

.font-size-85 {
  font-size:85%!important;
}

.font-size-80 {
  font-size:80%!important;
}

.font-size-75 {
  font-size:75%!important;
}

.font-size-70 {
  font-size:70%!important;
}

.bg-white {
  background-color: white;
}

.bg-text {
  padding: 3px 7px;
  color: white;
  border-radius: 2px;
}

.bg-text-danger {
  background: $corporateColor;
}

.bg-text-warning {
  background: #f39c12;
}

.bg-text-super-danger {
  background: $dangerColor;
}
.bg-text-success {
  background: #00a65a;
}

.bg-text-default {
  background: #f4f4f4;
}

.btn-left {
  text-align: left;
}

.site-wrapper {
  .box, .box-footer {
    background-color: #fafafa;
  }
}

.btn-danger.active {
  background-color: $corporateColor;
}

.btn-super-danger {
  background: $dangerColor;
}

.text-super-danger {
  color: $dangerColor;
}

.typeahead-search {
  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;

    &> .active > a {
      background-color: $corporateColor;
    }
  }
}

.direct-chat-messages {
  height: auto;

  .direct-chat-msg {
    &.right {
      .direct-chat-text {
        background-color: #feeee3;
      }
    }

    &.system {
      .direct-chat-text {
        background-color: #efefef;
      }
    }

    .direct-chat-text {
      background-color: #f3fef4;
    }
  }

}

.text-through {
  text-decoration: line-through;
}

.modal-mg {
  width: 1200px;
}

.modal-xl {
  width: 1350px;
}

.money-col {
  white-space: nowrap;
}

.dashed {
  border-bottom: dashed;
  border-width: 1px;
}

.rounded-block {
  border: 1px solid $lightGrey;
  border-radius: 5px;
  padding: 5px;
}

.grey-border-left {
  border-left: 3px solid $lightGrey;
}

.red-border-left {
  border-left: 3px solid $dangerColor;
}

.alert-panel {
  position: fixed;
  right: 10px;
  top: 20px;
  width: 350px;
  z-index: 10001;
  opacity: 0.9;
  .callout {
    position: relative;
    margin-bottom: 5px;
    padding: 10px 20px 10px 10px;
    .fa-remove {
      right: 15px;
      position: absolute;
    }
  }
}

div[nv-file-drop] {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background: #fcfff6;
  text-align: center;
  border: 1px dashed #679bff;
  font-size: 130%;
  vertical-align: middle;
  color: #343434;
  border-radius: 6px;
}

.uib-datepicker:focus,
.uib-daypicker:focus,
button:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: none!important;
  outline: 0 none!important;
}

.removed-bg {
  background: url('/bundles/ics/images/main/remove-backgroud.png');
}

@font-face {
  font-family: 'ALSRubl-Verdana';
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_arial/alsrubl-verdana-regular.eot');
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-regular.eot?#iefix') format('embedded-opentype'),url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-regular.woff') format('woff'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-regular.ttf') format('truetype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-regular.svg#ALSRublRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ALSRubl-Verdana';
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-italic.eot');
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-italic.eot?#iefix') format('embedded-opentype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-italic.woff') format('woff'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-italic.ttf') format('truetype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-italic.svg#ALSRublItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ALSRubl-Verdana';
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bold.eot');
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bold.eot?#iefix') format('embedded-opentype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bold.woff') format('woff'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bold.ttf') format('truetype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bold.svg#ALSRublBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ALSRubl-Verdana';
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bolditalic.eot');
  src: url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bolditalic.eot?#iefix') format('embedded-opentype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bolditalic.woff') format('woff'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bolditalic.ttf') format('truetype'), url('/bundles/ics/assetic/fonts/rub-fnt/b-rub_verdana/alsrubl-verdana-bolditalic.svg#ALSRublBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}


.b-rub, .b-rub_verdana {
  font-family: 'ALSRubl-Verdana', Verdana, sans-serif;
  line-height: normal;
  display: inline!important; 
}

.required-suffix {
  display: inline-block;
  color: red;
  margin-left: 4px;
}

tags-input {
  .tags {
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 10px;

    &.focused {
      box-shadow: none !important;
    }

    .input {
      padding-left: 0 !important;

      &::placeholder {
        color: #999;
      }
    }

    .tag-item {
      margin: 5px 2px;
      height: 22px;
      line-height: 21px;
      background-color: $corporateColor;
      border-color: #a40131;

      .remove-button {
        color: tomato;

        &:hover {
          color: red;
        }
      }
    }
  }
}

.ui-select-multiple {
  &.ui-select-bootstrap {
    padding: 4px 12px 3px !important;

    input.ui-select-search {
      width: auto !important;

      &::placeholder {
        color: #999;
      }
    }

    .ui-select-match-item {
      background-color: $corporateColor;
      border-color: #a40131;
      color: #fff;
    }

    .close {
      color: tomato;
      text-shadow: none;
      opacity: 1;
      margin: 4px 1px 0 4px;
      font-size: 16px !important;

      &:hover {
        color: red;
        text-shadow: none;
        opacity: 1;
      }
    }
  }
}

.ics-input-file {
  margin-bottom: 15px;

  .file-input {
    display: none;
  }

  .btn {
    border-radius: 0;
  }

  .hint {
    margin-left: 15px;
  }

  .preview {
    max-height: 100px;
    width: auto;
    margin-left: 7.5px;
  }

  .has-error {
    .btn {
      border-color: $corporateColor;
    }
  }

  .help-block {
    margin-bottom: 0;
  }
}

.order-item-tour-type-header {
  display: flex;
  flex-direction: row;
}

.order-tour-type-select-container {
  width: 300px;
}

.ui-select-container {
  span.form-control.btn-default {
    &:active {
      box-shadow: none;
      border-color: #ddd;
    }
  }

  span.btn-default {
    background-color: #fff;

    &:active {
      background-color: #fff;
    }

    &[disabled] {
      background-color: #f4f4f4;
    }
  }

  .ui-select-match-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ui-select-match {
  a.btn {
    color: $corporateColor;
    transition: 0.18s ease-in-out;

    &:active {
      box-shadow: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.order-item-tourists-list {
  display: inline-flex;
  flex-direction: column;
  row-gap: 4px;

  .order-item-tourist-additional-data {
    font-size: 10px;
    color: #777;

    &:hover {
      color: #777;
    }
  }

  .order-item-tourists-list-item {
    &__clickable {
      color: #3c8dbc;

      &:hover {
        color: #72afd2;
      }
    }
  }
}

@media print{
  .orders-with-type-expenses-container {
    break-before: left;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.avia-booking-search-row-tooltip {
  .tooltip-inner {
    max-width: 300px;
    padding: 8px;
    text-align: left;
    opacity: 0.87;
  }

  .direction-title {
    margin-bottom: 6px;
  }

  hr {
    margin: 6px 0;
  }

  .segments-container {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
}

.globus-order-empty-price-label {
  font-size: 12px;
}

.globus-order-price-title {
  .fa {
    font-size: 20px;
  }
}

.order-price-modal {
  @media (min-width: 992px) {
    .modal-lg {
      width: auto;
      max-width: 90%;
      min-width: 920px;
    }
  }
}

.order-price-form {
  .control-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .data-row {
    margin-bottom: 32px;

    @media (min-width: 992px) {
      margin-bottom: 24px;
    }
  }

  .order-price-form-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 24px;
    margin-bottom: 28px;
  }

  .form-group:not(.no-margin) {
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  .help-block {
    margin-bottom: 0;
    font-size: 12px;
  }

  .row-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .additional-payments-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .add-btn {
      align-self: flex-start;
    }

    .remove-additional-payment-btn {
      margin-top: 30px;
    }
  }

  textarea {
    resize: none;
    height: 14em;

    @media (min-width: 992px) {
      height: 6em;
    }
  }

  .visa-items-list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .visa-items-row {
      @media (min-width: 992px) {
        text-align: right;
      }

      .help-block {
        text-align: left;
      }
    }
  }

  .insurance-items-list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .tourist-data-col {
      @media (min-width: 992px) {
        text-align: right;
      }
    }

    .tourist-name {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .help-block {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.order-additional-payments-spinner {
  position:absolute;
  right: 10px;
  top: 9px;
}

.additional-payment-choice-row {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.order-price-additional-payment-row {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: flex-end;
}

.order-price-loading-placeholder {
  overflow: hidden;
  position: relative;;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: #eee;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ccc;
    z-index: 1100;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1200;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,0) 100%);
    animation: inputLoadingPlaceholder 1.25s infinite;
  }
}

.order-price-form-close-block {
  position: absolute;
  top: 2px;
  right: 10px;
  padding: 10px;
}

.order-price-header-refresher-icon {
  padding: 3px 4px;
  cursor: pointer;
  transition: 0.18s ease-in-out;

  &:hover {
    opacity: 0.76;
  }
}

.order-price-form-timer-container {
  font-size: 12px;
  font-family: monospace;
}

@keyframes inputLoadingPlaceholder{
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.order-price-form-tour-data-component {
  font-size: 12px;

  .data-row {
    margin-bottom: 8px;
  }

  .title {
    font-weight: bold;
  }
}

.order-price-save-alert {
  color: #31708f;
  background-color: #d9edf7;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #bce8f1;
  border-radius: 4px;
}