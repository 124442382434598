.scalendar {
  .evo-calendar {
    position: relative;
    background-color: #fbfbfb;
    color: #5a5a5a;
    width: 100%;
    -webkit-box-shadow: 0 10px 50px -20px #aa113f;
    box-shadow: 0 10px 50px -20px #aa113f;
    margin: 0 auto;
    overflow: hidden;
    z-index: 1;
  }

  .calendar-sidebar {
    position: absolute;
    margin-top: 0;
    width: 200px;
    height: 100%;
    float: left;
    background-color: #aa113f;
    color: #fff;
    z-index: 1;
    -webkit-box-shadow: 5px 0 18px -3px #aa113f;
    box-shadow: 5px 0 18px -3px #aa113f;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    z-index: 2;
  }

  .sidebar-hide .calendar-sidebar {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .calendar-sidebar > span#sidebarToggler {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    background-color: #aa113f;
    padding: 10px 8px;
    cursor: pointer;
    -webkit-box-shadow: 5px 0 18px -3px #aa113f;
    box-shadow: 5px 0 18px -3px #aa113f;
  }

  .calendar-sidebar > .calendar-year {
    padding: 20px;
    text-align: center;
  }
  .calendar-sidebar > .calendar-year > p {
    margin: 0;
    font-size: 30px;
    display: inline-block;
  }
  .calendar-sidebar > .calendar-year > button.icon-button {
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: visible;
  }
  .calendar-sidebar > .calendar-year > button.icon-button > span {
    border-right: 4px solid #fff;
    border-bottom: 4px solid #fff;
    width: 100%;
    height: 100%;
  }
  .calendar-sidebar > .calendar-year > img[year-val="prev"] {
    float: left;
  }
  .calendar-sidebar > .calendar-year > img[year-val="next"] {
    float: right;
  }
  .calendar-sidebar > .month-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .calendar-sidebar > .month-list::-webkit-scrollbar-track {
    background: transparent;
  }
  .calendar-sidebar > .month-list::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 5px;
  }
  .calendar-sidebar > .month-list::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
  }
  .calendar-sidebar > .month-list > .calendar-months {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .calendar-sidebar > .month-list > .calendar-months > li {
    padding: 7px 30px;
    cursor: pointer;
    font-size: 16px;
  }
  .calendar-sidebar > .month-list > .calendar-months > li:hover {
    background-color: #e3e3e3;
  }
  .calendar-sidebar > .month-list > .calendar-months > li.active-month {
    background-color: #686868;
  }

  .calendar-inner {
    position: relative;
    padding: 40px 30px;
    float: left;
    width: 100%;
    max-width: calc(100% - 600px);
    margin-left: 200px;
    background-color: #fff;
    -webkit-box-shadow: 5px 0 18px -3px rgba(0, 0, 0, 0.15);
    box-shadow: 5px 0 18px -3px rgba(0, 0, 0, 0.15);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 1;
  }
  .calendar-inner::after {
    content: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(83, 74, 109, 0.5);
  }
  .sidebar-hide .calendar-inner {
    max-width: calc(100% - 400px);
    margin-left: 0;
  }
  .event-hide .calendar-inner {
    max-width: calc(100% - 200px);
  }
  .event-hide.sidebar-hide .calendar-inner {
    max-width: 100%;
  }
  .calendar-inner .calendar-table {
    border-collapse: collapse;
    font-size: 20px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  th[colspan="7"] {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
    color: #aa113f;
  }
  th[colspan="7"]::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    width: 50px;
    height: 5px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: rgba(135, 115, 193, 0.15);
  }
  tr.calendar-header {

  }
  tr.calendar-header .calendar-header-day {
    padding: 10px;
    text-align: center;
    color: #5a5a5a;
  }

  tr.calendar-body {

  }
  tr.calendar-body .calendar-day {
    padding: 10px 0;
  }
  tr.calendar-body .calendar-day .day {
    position: relative;
    padding: 15px;
    height: 60px;
    width: 60px;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
    color: #5a5a5a;
    border: 1px solid transparent;
    -webkit-transition: all .3s ease, -webkit-transform .5s ease;
    transition: all .3s ease, -webkit-transform .5s ease;
    -o-transition: all .3s ease, transform .5s ease;
    transition: all .3s ease, transform .5s ease;
    transition: all .3s ease, transform .5s ease, -webkit-transform .5s ease;
    cursor: pointer;
  }
  tr.calendar-body .calendar-day .day:hover {
    background-color: #dadada;
  }
  tr.calendar-body .calendar-day .day:active {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  tr.calendar-body .calendar-day .day.calendar-active,
  tr.calendar-body .calendar-day .day.calendar-active:hover {
    color: #5a5a5a;
    border-color: rgba(0, 0, 0, 0.5);
  }

  tr.calendar-body .calendar-day .day.calendar-today {
    color: #fff;
    background-color: #aa113f;
  }
  tr.calendar-body .calendar-day .day.calendar-today:hover {
    color: #fff;
    background-color: #aa113f;
  }
  tr.calendar-body .calendar-day .day[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    color: #b9b9b9;
  }


  .calendar-events {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    padding: 70px 30px 60px 30px;
    background-color: #fbfbfb;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    overflow-y: auto;
    z-index: 0;
  }
  .calendar-events::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .calendar-events::-webkit-scrollbar-track {
    background: transparent;
  }
  .calendar-events::-webkit-scrollbar-thumb {
    background: #8e899c;
    border-radius: 5px;
  }
  .calendar-events::-webkit-scrollbar-thumb:hover {
    background: #6c6875;
  }

  .calendar-events > .event-header > p {
    font-size: 30px;
    font-weight: 600;
    color: #5a5a5a;
  }
  #eventListToggler {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    background-color: #aa113f;
    padding: 10px;
    cursor: pointer;
    -webkit-box-shadow: 5px 0 18px -3px #aa113f;
    box-shadow: 5px 0 18px -3px #aa113f;
    z-index: 1;
  }
  .event-list::after {
    content: "";
    clear: both;
    display: table;
  }
  .event-list > .event-empty {
    padding: 15px 10px;
    background-color: rgba(135,115,193,.15);
    border: 1px solid #aa113f;
  }
  .event-list > .event-empty > p {
    margin: 0;
    color: #aa113f;
  }
  .event-container {
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    cursor: pointer;
    float: left;
  }
  .event-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    transform: translate(29px, 30px);
    background-color: #eaeaea;
    z-index: -1;
  }
  .event-container:last-child.event-container::before {
    height: 30px;
    transform: translate(29px, 0);
  }
  .event-container:only-child.event-container::before {
    height: 0;
    opacity: 0;
  }
  .event-container:hover {
    background-color: #fff;
    -webkit-box-shadow: 0 3px 12px -4px rgba(0, 0, 0, 0.65);
    box-shadow: 0 3px 12px -4px rgba(0, 0, 0, 0.65);
  }
  .event-container > .event-icon {
    position: relative;
    padding: 20px;
    width: 60px;
    height: 60px;
    float: left;
    /* z-index: 0; */
  }
  /* .event-container > .event-icon::before {
      content: '';
      position: absolute;
      top: 0;
      left: 29px;
      width: 2px;
      height: calc(100% + 10px);
      background-color: #eaeaea;
      z-index: -1;
  } */
  /* .event-container:last-child > .event-icon::before {
      height: calc(50% + 10px);
  }
  .event-container:first-child > .event-icon::before {
      top: 50%;
      height: calc(50% + 10px);
  }
  .event-container:only-child > .event-icon::before {
      opacity: 0;
  } */
  .event-container > .event-icon > img {
    width: 30px;
  }
  .event-container > .event-info {
    align-self: center;
    width: calc(100% - 60px);
    display: inline-block;
    padding: 10px 10px 10px 0;
  }
  .event-container > .event-info > p {
    margin: 0;
    color: #5a5a5a;
  }
  .event-container > .event-info > p.event-title {
    position: relative;
    font-size: 22px;
    font-weight: 600;
  }
  .event-container > .event-info > p.event-title > span {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 12px;
    font-weight: normal;
    color:#aa113f;
    border: 1px solid #aa113f;
    border-radius: 3px;
    padding: 3px 6px;
    transform: translateY(-50%);
  }
  .event-container > .event-info > p.event-desc {
    font-size: 14px;
    margin-top: 5px;
  }


  .event-indicator {
    position: absolute;
    width: -moz-max-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, calc(-100% + -5px));
    -ms-transform: translate(-50%, calc(-100% + -5px));
    transform: translate(-50%, calc(-100% + -5px));
  }
  .event-indicator > .type-bullet {
    float: left;
    padding: 2px;
  }
  .event-indicator > .type-bullet > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .event-container > .event-icon > div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  /* fallback color */
  .event-container > .event-icon > div[class^="event-bullet-"],
  .event-indicator > .type-bullet > div[class^="type-"] {
    background-color: #aa113f;
  }
  .event-container > .event-icon > div.event-bullet-event,
  .event-indicator > .type-bullet > div.type-event {
    background-color: #ff7575;
  }
  .event-container > .event-icon > div.event-bullet-holiday,
  .event-indicator > .type-bullet > div.type-holiday {
    background-color: #ffc107;
  }
  .event-container > .event-icon > div.event-bullet-birthday,
  .event-indicator > .type-bullet > div.type-birthday {
    background-color: #3ca8ff;
  }


  button.icon-button {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
    cursor: pointer;
  }
  button.icon-button > span.bars {
    position: relative;
    width: 100%;
    height: 4px;
    display: block;
    background-color: #fff;
  }
  button.icon-button > span.bars::before,
  button.icon-button > span.bars::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    display: block;
    background-color: #fff;
  }
  button.icon-button > span.bars::before {
    top: -8px;
  }
  button.icon-button > span.bars::after {
    bottom: -8px;
  }
  button.icon-button > span.chevron-arrow-left {
    display: inline-block;
    border-right: 6px solid #fff;
    border-bottom: 6px solid #fff;
    width: 18px;
    height: 18px;
    -webkit-transform: rotate(-225deg);
    -ms-transform: rotate(-225deg);
    transform: rotate(-225deg);
  }
  button.icon-button > span.chevron-arrow-right {
    display: block;
    border-right: 4px solid #fff;
    border-bottom: 4px solid #fff;
    width: 16px;
    height: 16px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}