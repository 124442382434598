.airport-location, .airline-list, airline-search {
  .location-code-lable {
    font-weight: bold;
    position: absolute;
    right: 20px;
    bottom:6px;
    color: grey;
    background-color: white;
    padding: 4px 2px 0 8px;
  }

  .location-code-image {
    position: absolute;
    margin-top: 5px;
    margin-left: 7px;
    bottom: 5px;
  }

  .location-cross {
    right: 5px;
    padding: 0px;
    position: absolute;
    color: $corporateColor;
    bottom: 23px;
  }

  .airport-location-input {
    padding-left: 28px;

    &__multiple {
      padding-left: 12px;
    }
  }

  .dropdown-menu {
    width: 94%;
    a {
      padding: 10px;
      border-bottom: 1px dashed $corporateColor;

      .location-code {
        color: $corporateColor;
        float: right;
        font-weight: bold;
      }



      .location-name {
        margin-left: 30px;
      }

      .location-image {
        position: absolute;
        border: 1px solid #f4f4f4;
        margin-top: -2px;
      }

    }

    > .active {
      a {
        background-color: $corporateColor;
        .location-code {
          color: white;
        }

        .location-image {
          border: 1px solid $corporateColor;
        }
      }
    }
  }

}


@media (min-width: 1400px){
  .container {
    width: calc(100% - 100px);
  }
}

@media (min-width: 1940px){
  .container {
    width: calc(100% - 100px);

    .avia {
      @media (min-width: 1840px){
        .col-filter-menu {
          width: 16%;
        }

        .col-main-block {
          width: 84%;
        }

        avia-search-flight {
          width: 49%;
          display: inline-block;
        }

        avia-search-flight:nth-child(odd) {
          margin-left: 2%;
        }

      }
    }
  }



}

.avia {
  .wait-status, .search-form, .basket-block {
    max-width: 1440px;
    margin: auto;
    margin-bottom: 20px;
  }

  .search-form {
    .search-button-block {
      margin: 15px 0;
      .btn {
        width: 100%;

      }
    }

    .num-passenger {
      font-size: 200%;
      background-color: white;
      padding: 10px;
      border: 1px solid $lightGrey;
      border-radius: 4px;
    }

    .prefered-cabin-type {
      margin-top: 12px;
    }

    .row {
      margin-top: 0px;
    }

    input, select {
      margin: 0 5px;
    }

    .passengers-count {
      h4, .num-change {
        display: inline-block;
        margin-right: 10px;
      }

      &:first-child {
        margin-left: 5px;
      }
    }



  }

  .flight-results, .basket-block {
    .flight-item {

      &.box {
        &.is-invisible {
          margin-top: -20px;
          // background-color: #fff6ea;
        }

        .box-header {
          height: 60px;
          .btn {
            margin-top: 3px;
          }

          h1 {
            font-size: 250%;
            margin: 0px 16px 0 0;
            font-weight: normal;
            margin-top: 0px;
            margin-biottom: 0px;
            color: $corporateColor;
            display: inline-block;
            margin-left: 15px;

            span {
              font-size: 70%;
              font-weight: normal;
            }

          }

          a {
            border-bottom: 1px dashed ;
          }
        }

        .box-body {
          padding: 0 10px 10px 10px;

          .flight-block {
            // margin-top: 10px;
          }



          .airline-info {
            h4 {
              font-size: 170%;
              margin-left: 35px;
              display: inline-block;
              margin-top: 0px;
              margin-bottom: 0px;
            }

            .tariff-info {
              white-space: nowrap;
              margin-left: 35px;
            }

            img {
              position: absolute;
            }

          }

          .direction-item {
            background-color: $lightGrey;
            padding: 10px;
            border: 1px solid $grey;
            > div {
              > nobr > div, i {
                display: inline-block;
                font-size: 120%;
                margin-right: 6px;

                &.city {
                  color: $corporateColor;
                }

                &.airport-code {
                  font-weight: bold;
                  color: $darkGrey;
                }
              }
            }

            .airline-logo {
              width: 100px;
              height: 70px;
              margin-top: -25px;
              margin-left: 10px;
              position: absolute;
            }

          }

          .route-item {
            .connections {
              h5 {
                color: $darkGrey;
                font-weight: bold;
              }
            }

            .fa-fighter-jet {
              font-size: 160%;
              color: $darkGrey;
              margin: 12px 20px;

              &.rotate {
                transform: rotate(180deg);
              }
            }

            h2{
              margin-top: 5px;
              margin-bottom: 0px;
            }

            h5 {
              margin-top: 18px;
              margin-bottom:2px;
            }

          }

          .baggage {
            h4 {
              margin-top: 12px;
            }

            .fa-suitcase {
              color: $darkGrey;
            }
          }

        }
      }

      .grey-block {
        color: $darkGrey;
      }



    }
  }

  .basket-float {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid white;
    z-index: 10001;
    background: $corporateColor;
    text-align: center;
    color: white;
    vertical-align: middle;

    h4 {
      margin-top: 12px;
      display: inline-block;

      b {
        font-size: 120%;
      }
    }

    .btn {
      margin-left: 15px;
      margin-top: -6px;
    }

    a.basket-link {
      color: white;
      border-bottom: 1px dashed white;
    }

    .info-box {
      min-height: 75px;
      .info-box-icon {
        height: 75px;
        line-height: 70px;
      }

    }
  }

  .basket-block {
    .ticket-item {
      .info-line {
        background-color: $corporateColor;
        color: white;
        padding: 2px 20px;
        border-radius: 4px;

        small {
          color: white;
          font-size: 60%;
        }


        h2 {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
      .airline-info {
        h4 {
          font-size: 170%;
          margin-left: 35px;
          display: inline-block;
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .tariff-info {
          white-space: nowrap;
          margin-left: 35px;
        }

        img {
          position: absolute;
        }

      }

      .direction-item {
        padding: 10px;
        min-height: 60px;
        .tracking-info {
          div, i {
            display: inline-block;
            font-size: 110%;
            margin-right: 6px;

            &.city {
              color: $corporateColor;
            }

            &.airport-code {
              font-weight: bold;
              color: $darkGrey;
            }
          }
        }

        .airline-logo {
          width: 100px;
          height: 70px;
          margin-top: -12px;
          margin-left: 10px;
          position: absolute;
        }

      }

      .route-item {
        .connections {
          h5 {
            color: $darkGrey;
            font-weight: bold;
          }
        }

        .fa-fighter-jet {
          font-size: 160%;
          color: $darkGrey;
          margin: 12px 20px;

          &.rotate {
            transform: rotate(180deg);
          }
        }

        h2{
          margin-top: 5px;
          margin-bottom: 0px;
        }

        h5 {
          margin-top: 18px;
          margin-bottom:2px;
        }

      }

      .baggage {
        h4 {
          margin-top: 12px;
        }

        .fa-suitcase {
          color: $darkGrey;
        }
      }
    }
  }

  .booking-list {
    .booking-item {
      &.invalid-booking {
        background-color: #ffeff2;
      }


      h1 {
        margin-top: 2px;
        margin-bottom: 2px;
      }

      h3 {
        margin-top: 12px;
      }

    }
  }

  .add-airport-container {
    margin: 15px;
  }

  .airports-filter-row {
    margin-bottom: 8px;
  }

  .airports-list-pagination-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .airports-list-pagination-subtitle {
    margin-right: 4px;
  }

  .airports-limit-control {
    margin: 20px 0;
  }

  .airports-limit-subtitle {
    min-width: 132px;
  }

  .passengers-add-btn {
    user-select: none;
  }

  .add-avia-stop-sale-rule-container {
    margin: 15px 0 15px -15px;
  }

  .passenger-action-icon {
    display: inline-block;
    padding: 0 4px;
    cursor: pointer;
    color: #3c8dbc;
    transition: 0.3s ease-in-out;

    &__danger {
      color: #aa113f;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  .stop-sale-rules-action-container {
    min-width: 72px;
  }
}

.price-block {
  &>div {
    margin: 0 10px;
  }
}

.passenger-form-hint {
  margin-top: -8px;
  margin-bottom: 16px;
  padding-left: 24px;
}

.booking-segments-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .booking-segment-container {
    display: inline-block;
    padding-right: 140px;
    position: relative;

    .top-actions-container {
      position: absolute;
      top: 10px;
      right: 0;
    }

    .bottom-actions-container {
      position: absolute;
      bottom: 10px;
      right: 0;
    }

    .segment-button {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.airline-search-single-mode-value-container {
  position: absolute;
  left: 12px;
  top: 7px;
  width: calc(100% - 24px);
  background: #fff;
}

.airlines-page {
  .airlines-search-form-container {
    display: block;
    max-width: 1440px;
    margin: 0 auto 20px;
  }

  .airlines-search-form {
    .actions-row {
      margin-top: 16px;

      .action-btn {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .add-airline-btn {
    display: inline-block;
    margin: 0 0 16px;
  }

  .airline-loader-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .fa {
      margin-right: 4px;
    }
  }

  .airline-entity-actions-container {
    min-width: 60px;
  }

  .airlines-pagination-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 74px;

    .airline-pagination-label {
      margin-right: 4px;

      &__limit {
        min-width: 144px;
      }
    }
  }

  .airline-logo {
    height: 28px;
    width: auto;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: scale(2.4);
      border: 1px solid #f8f8f8;
      background: #fff;
      padding: 1px;
    }
  }
}

.stop-sale-rules-page {
  .table {
    .applies-to-col-content {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }
  }
}

.city-select-autocomplete-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    min-width: 44px;
    text-align: center;

    em {
      background: transparent !important;
      color: tomato !important;
      font-family: inherit !important;
      font-size: inherit !important;
      font-style: normal !important;
    }
  }
}

.tour-payments {
  .tour-payments-search-form-container {
    display: block;
    max-width: 1440px;
    margin: auto auto 20px;

    .actions-buttons-container {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
    }
  }

  .list-item-actions-container {
    min-width: 64px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
  }

  .tour-id-input {
    max-width: 200px;
  }

  .tour-id-cell {
    min-width: 200px;
  }

  .tour-payments-search-form-block {
    position: relative;

    .tour-payments-ref-block {
      position: absolute;
      right: 32px;
      bottom: 5px;

      .ref-value {
        text-decoration: underline;
      }
    }
  }
}

.block-data-page {
  .block-data-content {
    display: flex;
    flex-direction: row;
    column-gap: 12px;

    .block-data-departure-flight-content-wrapper {
      display: block;
      flex-grow: 1;
    }

    .departure-flights-list-container {
      max-width: 200px;
      border: 1px solid $darkGrey;

      .departure-flights-list-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 4px;
        padding: 12px;
        border-bottom: 1px solid $darkGrey;
        background-color: #f5f5f5;
        margin: 0;
        font-size: 16px;
        font-weight: bold;
      }

      .departure-flights-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .departure-flights-list-item {
          padding: 5px;
          border-bottom: 1px solid $darkGrey;
          cursor: pointer;
          transition: 0.16s ease-in-out;

          &:last-child {
            border-bottom: none;
          }

          &:not(.departure-flights-list-item__active):hover {
            background-color: rgba(170, 17, 63, 0.1);
          }

          &__active {
            background-color: $corporateColor;
            color: #fff;

            .flight-description {
              color: #dedede;
            }
          }
        }
      }
    }

    .departure-flight-content-wrapper {
      flex-grow: 1;

      .departure-flight-header {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
        align-items: center;
        background-color: #f5f5f5;
        padding: 6px 12px;
        border: 1px solid $darkGrey;
        margin-bottom: 8px;

        .departure-flight-title {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
        }

        .ticket-class-filter-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 4px;

          .class-title {
            font-weight: bold;
          }

          .btn {
            transition: 0.16s ease-in-out;

            &:not(.btn-danger):hover {
              background-color: rgba(170, 17, 63, 0.1);
            }
          }

          .btn-danger {
            color: #fff;
          }
        }
      }

      .departure-flight-data-wrapper {
        display: flex;
        flex-direction: row;
        column-gap: 12px;
      }

      .departure-schedule-table {
        max-width: 310px;
        border-color: $darkGrey;

        thead th {
          border-color: $darkGrey;
        }

        tbody tr {
          cursor: pointer;

          td {
            border-color: $darkGrey;
          }

          &.active {
            td {
              color: #fff;
              background-color: $corporateColor;
            }
          }
        }
      }
    }

    .return-flights-wrapper {
      flex-grow: 1;
    }

    .return-flights-list-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      column-gap: 4px;
      margin-bottom: 8px;

      .return-flights-list-title {
        font-weight: bold;
      }

      .return-flights-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        border: 1px solid $darkGrey;

        .return-flights-list-item {
          border-right: 1px solid $darkGrey;
          transition: 0.16s ease-in-out;
          padding: 5px;
          cursor: pointer;

          &:last-child {
            border-right: none;
          }

          &:not(.return-flights-list-item__active):hover {
            background-color: rgba(170, 17, 63, 0.1);
          }

          &__active {
            color: #fff;
            background-color: $corporateColor;

            .flight-description {
              color: #dedede;
            }
          }
        }
      }
    }

    .block-data-price-input-group {
      min-width: 200px;
    }

    .block-data-price-value-container {
      white-space: nowrap;
    }

    .flight-description {
      margin-top: 2px;
      font-size: 11px;
      color: #666;
    }

    .flight-title {
      font-weight: bold;
    }
  }

  .block-data-filter {
    max-width: 1440px;
    margin: 0 auto 20px;
  }

  .table-hover {
    tbody tr {
      transition: 0.16s ease-in-out;

      &:hover {
        background-color: rgba(170, 17, 63, 0.1);
      }
    }
  }

  .block-data-price-input-group-wrapper {
    .input-group-addon {
      background-color: #eee;
    }
  }

  .schedule-item-checkbox-cell {
    text-align: center;
    vertical-align: top;
    cursor: pointer;
  }

  .block-data-selected-items-prices-container {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: flex-end;
    border: 1px dashed #efefef;
    padding: 8px 12px;
    margin-bottom: 12px;
    transition: all ease-in-out 0.2s;

    &.ng-hide {
      opacity: 0;
    }

    .title {
      min-width: 276px;
      font-weight: bold;
    }

    .selected-items-price-label {
      font-weight: bold;
      text-transform: lowercase;
      font-size: 12px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  .block-data-selected-items-note {
    margin-bottom: 12px;
  }

  .block-data-rt-prices-table {
    .currency-cell {
      vertical-align: middle;
      text-align: center;
    }
  }

  .block-data-flight-description {
    font-size: 10px;
    color: #777;
  }

  .ui-select-choices-row.active {
    .block-data-flight-description {
      color: #efefef;
    }
  }

  .countries-select {
    display: block;
    margin-bottom: 20px;
  }
}

.block-data-currency-select-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 8px;
  margin: 8px 0;

  label {
    margin-bottom: 0;
  }

  select {
    flex-basis: 150px;
  }
}

.block-data-departure-schedule-tooltip,
.block-data-return-schedule-tooltip {
  .tooltip-inner {
    max-width: 500px;
  }
}